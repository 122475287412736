import { i18n } from "@/plugins/i18n";

export default {
  /**
   * Action that handles the serverError and sets the correct alert.
   *
   * @param: {Vuex context} context - The current scoped context of the store.
   * @param: {Object} serverError - Response from server in case of an error.
   * @property: {String} serverResponse.response.data.msg - Message from API containing message to display.
   */
  handleError(context, serverError) {
    if (serverError.response != null && serverError.response != undefined) {
      const alert = serverError.response.data.msg;
      const i18nkey = "server_errors_text." + alert;
      var alertVariant = "danger";

      let alertData = {
        alertVariant: alertVariant,
      };
      if (i18n.te(i18nkey)) {
        alertData.alert = i18n.t(i18nkey);
      } else {
        alertData.alert = alert;
      }
      context.dispatch("setAlert", alertData);
    }
  },
  /**
   * Action that handles the serverResponse and sets the correct alert.
   * If the message is a key of server errors, use i18n, otherwise just use message.
   *
   * @param: {Vuex context} context - The current scoped context of the store.
   * @param: {Object} serverResponse - Response from server in case of success.
   * @property: {String} serverResponse.response.data.msg - Message from API containing message to display.
   */
  handleResponse(context, serverResponse) {
    const alert = serverResponse.data.msg;
    const i18nkey = "server_errors_text." + alert;
    var alertVariant = "success";

    let alertData = {
      alertVariant: alertVariant,
    };
    if (i18n.te(i18nkey)) {
      alertData.alert = i18n.t(i18nkey);
    } else {
      alertData.alert = alert;
    }
    context.dispatch("setAlert", alertData);
  },
  /**
   * Action that commits mutations to set alertData in store, this allows App.vue to show the alert to user.
   *
   *
   * @param: {Vuex context} context - The current scoped context of the store.
   * @param: {Object} alertData - Object containing data to display.
   * @property: {String} alertData.alert - Text to display in the alert.
   * @property: {String} alertData.alertVariant - Variant of the alert, can be any of the alert variantes specified by
   * bootstrap-vue docs.
   */
  setAlert(context, alertData) {
    context.commit("setAlert", alertData.alert);
    context.commit("setAlertVariant", alertData.alertVariant);
    context.commit("setShowAlert", true);
  },
  /**
   * Action that hides the alert from the user by setting showAlert in store to false.
   *
   * @param: {Vuex context} context - The current scoped context of the store.
   */
  hideAlert(context) {
    context.commit("setShowAlert", false);
  },
};
