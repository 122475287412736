import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

export default {
  state: () => ({
    currentActiveGrowthplan: {},
    completedQuestions: [],
    growthplanSteps: [],
    currentQuestion: 0,
  }),
  getters,
  mutations,
  actions,
};
