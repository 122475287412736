import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

export default {
  state: () => ({
    feedback: [],
    savingFeedback: false,
    feedbackLastSaved: null,
  }),
  getters,
  mutations,
  actions,
};
