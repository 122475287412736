import Vue from "vue";
import VueI18n from "vue-i18n";
import store from "@/store";

Vue.use(VueI18n);

/* Load messages from local storage.
 * This ensures that even if the backend does not respond, the user can read texts.
 */
const messages = {
  en: require("./langs/en-general.json"),
  fi: require("./langs/fi-general.json"),
};

// Create i18n object, with locale set to the selectedLanguage if it exists in the store.
export const i18n = new VueI18n({
  locale: store.getters.selectedLanguage,
  fallbackLocale: "en",
  messages: messages,
});

export default i18n;
