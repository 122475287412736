export default {
  getAnswers: (state, getters) => (storeDataName) => {
    return state.userAnswers[getters.currentActiveGrowthplanId][storeDataName];
  },
  savingAnswer: (state) => {
    return state.savingAnswer;
  },
  questionLastSaved: (state) => {
    return state.questionLastSaved;
  },
};
