import { extend } from "vee-validate";
import { configure } from "vee-validate";
import { email, required, max, min, numeric } from "vee-validate/dist/rules";

/**
 * Function that extends VeeValidate fields that are nessecary, and adds new validation fields to use in the app.
 * @param {Vue instance} vue - Instance of Vue to use for initialising the strings.
 * @param {Boolean} useI18n - Toggle configure, should be true in App, false in tests.
 */
export default function (vue, useI18n) {
  /**
   * Use basis validation for required, max, min, and email that came with VeeValidate.
   */
  extend("required", { ...required });
  extend("max", { ...max });
  extend("min", { ...min });
  extend("email", { ...email });
  extend("numeric", { ...numeric });

  /**
   * Make sure string contains at least 1 uppercase character.
   * Also init custom validation message, because Python backend does not handle camel case.
   */
  extend("upCase", {
    validate: (value) => value.match(/[A-Z]/g) !== null,
    message: (field, values) => {
      values._field_ = vue.$t(`credentials_text.${field}`);
      return vue.$t("validation_errors_text.up_case", values);
    },
  });

  /**
   * Make sure string contains at least one special character.
   * Also init custom validation message, because Python backend does not handle camel case.
   */
  extend("specialChar", {
    validate: (value) =>
      value.match(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/) !== null,
    message: (field, values) => {
      values._field_ = vue.$t(`credentials_text.${field}`);
      return vue.$t("validation_errors_text.special_char", values);
    },
  });

  /**
   * Make sure string contains at least one number.
   */
  extend("number", {
    validate: (value) => value.match(/[0-9]/g) !== null,
  });

  /**
   * Check if password confirmation matches
   */
  extend("password", {
    params: ["target"],
    validate(value, { target }) {
      return value === target;
    },
  });

  /**
   * Init all messages according to i18n.
   * @param {String} field - Name as provided as prop to ValidationProvider. Will be looked up in 'credentials_text'
   * @param {String} values - Pass to i18n to show the appropriate error. Subkey of 'validation_errors_text'
   */
  if (useI18n) {
    configure({
      defaultMessage: (field, values) => {
        if (field.indexOf("-") > -1) {
          field = field.substring(0, field.lastIndexOf("-"));
        }
        if (field in vue.$t("credentials_text")) {
          values._field_ = vue.$t(`credentials_text.${field}`);
          return vue.$t(`validation_errors_text.${values._rule_}`, values);
        }
      },
    });
  }
}
