export default {
  setToken(state, token) {
    state.token = token;
  },
  setRole(state, role) {
    state.role = role;
  },
  destroyToken(state) {
    state.token = null;
  },
};
