import axiosInstance from "@/axios";

export default {
  /**
   * Action that attempts to retrieve the growthplans that belong to the client of the current consultant.
   *
   * @param: {Vuex context} context - The current context of the store.
   * @return: {Promise<Object>} response - Response from api in case of correct retrieval of data.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  retrieveConsultantGrowthplans() {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/consultant/get/user/all/growthplans")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Action that attempts to retrieve profiles of all clients that belong to current consultant.
   *
   * @param: {Vuex context} context - The current context of the store.
   * @return: {Promise<Object>} response - Response from api in case of correct retrieval of data.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  retrieveConsultantClients() {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/consultant/get/clients/all")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Action that attempts to retrieve details of the current consultant's profile.
   *
   * @param: {Vuex context} context - The current context of the store.
   * @return: {Promise<Object>} response - Response from api in case of correct retrieval of data.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  retrieveConsultantProfile() {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/consultant/get/details")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Action that attempts to give user a new Growth Plan.
   *
   * @param: {Vuex context} context - The current context of the store.
   * @return: {Promise<Object>} response - Response from api in case of correct retrieval of data.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  giveUserNewGrowthplan(context, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .patch(`/api/user/${payload.userId}/patch/new-growthplan`, {
          growthplan_credit: payload.growthplanCredit,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Action that attempts to retrieve if user can make a new Growth Plan.
   *
   * @param: {Vuex context} context - The current context of the store.
   * @return: {Promise<Object>} response - Response from api in case of correct retrieval of data.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  retrieveUserAllowedNewGrowthplan(context, userId) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/user/${userId}/get/allowed-new-growthplan`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteConsultantAccount(context, email) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .delete(`/api/consultant/${email}/delete`)
        .then((response) => {
          context.dispatch("handleResponse", response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
