<template>
  <div>
    <b-skeleton-wrapper :loading="!loaded">
      <template #loading>
        <b-navbar :sticky="true" toggleable="md">
          <b-skeleton width="100%" height="56px"></b-skeleton>
        </b-navbar>
      </template>
      <b-navbar :sticky="true" toggleable="md">
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item
              v-if="loggedIn && role == 'User'"
              to="/client/dashboard"
              data-test="nav-user-dashboard"
              >{{ $t("navbar_text.dashboard") }}</b-nav-item
            >
            <b-nav-item
              v-if="loggedIn && role == 'Consultant'"
              to="/consultant/dashboard"
              >{{ $t("navbar_text.dashboard") }}</b-nav-item
            >
            <b-nav-item
              v-if="loggedIn && role == 'Admin'"
              to="/admin/dashboard"
              >{{ $t("navbar_text.dashboard") }}</b-nav-item
            >
            <b-nav-item
              id="start-growthplan"
              v-if="loggedIn && !anyActiveGrowthplan && role == 'User'"
              @click="onStartGrowthplan"
              >{{ $t("navbar_text.start_growthplan") }}</b-nav-item
            >
            <b-nav-item
              id="my-growthplan"
              v-if="loggedIn && anyActiveGrowthplan && role == 'User'"
              @click="onNavigateToGrowthplan"
              data-test="growthplan-continue-nav"
              >{{ $t("navbar_text.my_growthplan") }}</b-nav-item
            >
            <b-nav-item
              id="growthplan-all-todos"
              v-if="loggedIn && anyActiveGrowthplan && role == 'User'"
              @click="onNavigatoToAllTodos"
              data-test="to-all-todos"
              >{{ $t("general_dashboard_text.all_todo") }}</b-nav-item
            >
            <b-nav-item v-if="loggedIn" @click="logout" data-test="logout">{{
              $t("navbar_text.logout")
            }}</b-nav-item>
          </b-navbar-nav>
          <b-navbar-nav class="ml-auto">
            <LocaleSwitcher />
          </b-navbar-nav>
        </b-collapse>
        <b-navbar-brand to="/">
          <b-img src="@/assets/ProGrowth-Logo-scaled.png" height="50px"></b-img>
        </b-navbar-brand>
      </b-navbar>
    </b-skeleton-wrapper>
  </div>
</template>

<script>
import LocaleSwitcher from "@/components/LocaleSwitcher";

export default {
  /**
   * Navbar for the Growth Plan app.
   * @displayName Navbar
   */
  name: "Navbar",
  components: {
    LocaleSwitcher,
  },
  data() {
    return {
      logoLoading: true,
      test: true,
      upsellModalId: "upsell-modal",
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("destroyToken").then(() => {
        this.$router.push({ name: "home" });
      });
    },
    /**
     * Retrieves from API if the user is allowed to create a new Growth Plan.
     * If the user is allowed to make this new Growth Plan, push user to Growth Plan start page.
     * If the user is not allowed, open the upsell modal with info on how to get accesss to a new Growth Plan.
     * Triggered by clicking "Start GrowthPlan" button in the dashboard.
     */
    onStartGrowthplan() {
      this.$store.dispatch("retrieveAllowedNewGrowthplans").then((response) => {
        if (response.data === true) {
          this.$router.push({ name: "StartPage" });
        } else {
          this.openUpsellModal();
        }
      });
    },
    async onNavigateToGrowthplan() {
      await this.$store.dispatch("updateFormCurrentQuestion", 0);
      this.$router.push({ name: "ClientGrowthplanContainer" });
    },
    async onNavigatoToAllTodos() {
      this.$router.push({ name: "AllTodo" });
    },
    openUpsellModal() {
      this.$bvModal.show(this.upsellModalId);
    },
  },
  computed: {
    /**
     * Get's from store if the user is logged in.
     * Store bases this on existence of a JWT.
     */
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    /**
     * Get role of user account from the store.
     * @values User, Consultant, Admin
     */
    role() {
      return this.$store.getters.role;
    },
    /**
     * Check if user has any active growthplans.
     */
    anyActiveGrowthplan() {
      if (
        this.$store.getters.currentActiveGrowthplanId > 0 &&
        this.$store.getters.currentActiveGrowthplanId != undefined
      ) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * Variable in Vuex store that indicates if language have been loaded.
     */
    loaded() {
      return this.$store.getters.loadedLangGeneral;
    },
  },
};
</script>
