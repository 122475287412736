export default {
  selectedLanguage(state) {
    return state.selectedLanguage;
  },
  loadedLangForMe(state) {
    return state.loadedLangForMe;
  },
  loadedLangGeneral(state) {
    return state.loadedLangGeneral;
  },
  countingDuration(state) {
    return state.countingDuration;
  },
  showLoading(state) {
    return state.showLoading;
  },
};
