<template>
  <footer class="app-footer">
    <b-skeleton-wrapper :loading="!loaded">
      <template #loading>
        <b-row>
          <b-skeleton width="100%" height="203px"></b-skeleton>
        </b-row>
      </template>
      <b-row>
        <b-col cols="12" lg="6">
          <h2 class="footer-heading">
            {{ $t("footer_text.footer_heading_1") }}
          </h2>
          <p class="footer-text">
            {{ $t("footer_text.footer_content_1") }}
          </p>
        </b-col>
        <b-col cols="12" lg="2">
          <h2 class="footer-heading">
            {{ $t("footer_text.footer_heading_2") }}
          </h2>
          <ul class="list-unstyled">
            <li class="footer-list-item">
              <b-link
                class="footer-link"
                target="_blank"
                href="https://www.progrowth.fi/palvelut/"
                >{{ $t("footer_text.sitemap_item_1") }}</b-link
              >
            </li>
            <li class="footer-list-item">
              <b-link
                class="footer-link"
                target="_blank"
                href="https://www.progrowth.fi/valmennukset/"
                >{{ $t("footer_text.sitemap_item_2") }}</b-link
              >
            </li>
            <li class="footer-list-item">
              <b-link
                class="footer-link"
                target="_blank"
                href="https://www.progrowth.fi/asiantuntijat/"
                >{{ $t("footer_text.sitemap_item_3") }}</b-link
              >
            </li>
            <li class="footer-list-item">
              <b-link
                class="footer-link"
                target="_blank"
                href="https://blogi.progrowth.fi/blogi"
                >{{ $t("footer_text.sitemap_item_4") }}</b-link
              >
            </li>
            <li class="footer-list-item">
              <b-link
                class="footer-link"
                target="_blank"
                href="https://www.progrowth.fi/yhteystiedot/"
                >{{ $t("footer_text.footer_heading_3") }}</b-link
              >
            </li>
          </ul>
        </b-col>
        <b-col cols="12" lg="2">
          <h2 class="footer-heading">
            {{ $t("footer_text.footer_heading_3") | capitalize }}
          </h2>
          <p class="footer-text">{{ $t("footer_text.footer_content_3") }}</p>
          <ul class="list-inline">
            <li class="list-inline-item">
              <b-link
                target="_blank"
                href="https://www.facebook.com/progrowth.oy/"
              >
                <font-awesome-icon
                  class="footer-icon"
                  :icon="['fab', 'facebook']"
                />
              </b-link>
            </li>
            <li class="list-inline-item">
              <b-link
                target="_blank"
                href="https://www.linkedin.com/company/pro-growth-oy/"
              >
                <font-awesome-icon
                  class="footer-icon"
                  :icon="['fab', 'linkedin']"
                />
              </b-link>
            </li>
          </ul>
        </b-col>
        <b-col
          cols="12"
          lg="2"
          class="d-xl-flex justify-content-xl-center align-items-xl-start"
        >
          <b-img
            class="footer-logo-img"
            src="@/assets/ProGrowth-Logo-scaled.png"
          ></b-img>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h4 class="footer-copyright-heading">
            {{ $t("footer_text.copyright") }}
          </h4>
        </b-col>
        <b-col class="d-lg-flex justify-content-lg-end">
          <ul class="list-inline">
            <li class="list-inline-item">
              <b-link to="/privacy-policy" class="footer-link p-t-c">{{
                $t("footer_text.privacy")
              }}</b-link>
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-skeleton-wrapper>
  </footer>
</template>

<script>
export default {
  /**
   * Footer for Growth Plan app.
   * @displayName Footer
   */
  name: "Footer",
  computed: {
    /**
     * Variable in Vuex store that indicates if language have been loaded.
     */
    loaded() {
      return this.$store.getters.loadedLangGeneral;
    },
  },
  filters: {
    /**
     * Uppercase strings
     */
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.toUpperCase();
    },
  },
};
</script>
