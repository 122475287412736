export default {
  setFeedback(state, value) {
    state.feedback = value;
  },
  setSavingFeedback(state, saving) {
    state.savingFeedback = saving;
  },
  setFeedbackLastSaved(state, date) {
    state.feedbackLastSaved = date;
  },
};
