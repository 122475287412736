import axiosInstance from "@/axios";

export default {
  /**
   * Action that attempts to retrieve the last 5 growthplans.
   *
   * @param: {Vuex context} context - The current context of the store.
   * @return: {Promise<Object>} response - Response from api in case of correct retrieval of data.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  retrieveAdminDashboardData() {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/admin/get/dashboard-data")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Action that attempts to retrieve the last 5 growthplans.
   *
   * @param: {Vuex context} context - The current context of the store.
   * @return: {Promise<Object>} response - Response from api in case of correct retrieval of data.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  retrieveAllGrowthplans() {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/growthplan/get/all")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Action that attempts to retrieve profiles of the last 5 consultants.
   *
   * @param: {Vuex context} context - The current context of the store.
   * @return: {Promise<Object>} response - Response from api in case of correct retrieval of data.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  retrieveAdminDashboardConsultantProfiles() {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/admin/get/dashboard-consultant-accounts")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Action that attempts to retrieve profiles of the last 5 consultants.
   *
   * @param: {Vuex context} context - The current context of the store.
   * @return: {Promise<Object>} response - Response from api in case of correct retrieval of data.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  retrieveAllConsultants() {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/consultant/get/all")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Action that attempts to retrieve profiles of the last 5 consultants.
   *
   * @param: {Vuex context} context - The current context of the store.
   * @return: {Promise<Object>} response - Response from api in case of correct retrieval of data.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  retrieveConsultantDetails(context, userId) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/consultant/${userId}/get/details`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Action that attempts to retrieve profiles of the last 5 clients.
   *
   * @param: {Vuex context} context - The current context of the store.
   * @return: {Promise<Object>} response - Response from api in case of correct retrieval of data.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  retrieveAdminDashboardClientProfiles() {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/admin/get/dashboard-client-accounts")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Action that attempts to retrieve profiles of the last 5 clients.
   *
   * @param: {Vuex context} context - The current context of the store.
   * @return: {Promise<Object>} response - Response from api in case of correct retrieval of data.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  retrieveAllUsers() {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/user/get/all")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Action that attempts to retrieve details of a specific client for client detail page of consultant dashboard.
   *
   * @param: {Vuex context} context - The current context of the store.
   * @param: {Number} id - User ID to find correct user from which to display details.
   * @return: {Promise<Object>} response - Response from api in case of correct retrieval of data.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  retrieveUserDetails(context, userId) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/user/${userId}/get/details`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Action that attempts to retrieve details of a specific client for client detail page of consultant dashboard.
   *
   * @param: {Vuex context} context - The current context of the store.
   * @param: {Number} id - User ID to find correct user from which to display details.
   * @return: {Promise<Object>} response - Response from api in case of correct retrieval of data.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  retrieveUserDetailsByConsultant(context, consultantEmail) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/consultant/${consultantEmail}/get/clients`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Action that attempts to retrieve the last 5 growthplans.
   *
   * @param: {Vuex context} context - The current context of the store.
   * @return: {Promise<Object>} response - Response from api in case of correct retrieval of data.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  updateConsultantClients(context, updateData) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .patch("/api/consultant/update/clients", {
          clients: updateData.clients,
          current_consultant_id: updateData.currentConsultantId,
          new_consultant_id: updateData.newConsultantId,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Action that attempts to retrieve details of the current admin's profile.
   *
   * @param: {Vuex context} context - The current context of the store.
   * @return: {Promise<Object>} response - Response from api in case of correct retrieval of data.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  retrieveAdminProfile() {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/admin/get/profile")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
