export default {
  setSelectedLanguage(state, lang) {
    state.selectedLanguage = lang;
  },
  setLoadedLangGeneral(state, loaded) {
    state.loadedLangGeneral = loaded;
  },
  setLoadedLangForMe(state, loaded) {
    state.loadedLangForMe = loaded;
  },
  setCountingDuration(state, duration) {
    state.countingDuration = duration;
  },
  setShowLoading(state, loading) {
    state.showLoading = loading;
  },
};
