import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

const getDefaultAlertState = () => {
  return {
    alert: "",
    alertVariant: "",
    showAlert: false,
  };
};

const state = getDefaultAlertState();

mutations.setDefaultAlert = (state) => {
  Object.assign(state, getDefaultAlertState());
};

export default {
  state,
  getters,
  mutations,
  actions,
};
