import axios from "axios";
import router from "@/router";
import store from "@/store";

// Create a new axios instance with the base url enviroment variable as the base for all requests
const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (config) => {
    const token = store.getters["token"];
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

function logOut() {
  store.dispatch("resetAuthState");
  router.push({ name: "home" });

  const alertData = {
    alert: "Session expired, please log in again",
    alertVariant: "danger",
    scroll: false,
  };
  store.dispatch("setAlert", alertData);
}

// Setup interceptors that deal with 401, 404 and 500 HTTP errors
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (
        error.response.status === 401 &&
        error.response.config.url.includes("login") === false &&
        error.response.config.url.includes("change-password") === false &&
        error.response.config.url.includes("create-password") === false &&
        error.response.config.url.includes("reset-password") === false
      ) {
        logOut();
      } else if (error.response.status == 422) {
        const msg = error.response.data.msg;
        if (
          msg !== undefined &&
          msg.match("[Ss]ignature verification failed")
        ) {
          logOut();
        }
      } else if (error.response.status === 404) {
        router.push({ name: "NotFound" });
      } else if (error.response.status === 500) {
        router.push({ name: "ServerError" });
      } else {
        store.dispatch("handleError", error);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
      return Promise.reject(error);
    } else {
      const alertData = {
        alert:
          "Couldn't connect to server, please check your internet connection",
        alertVariant: "danger",
        scroll: true,
      };
      store.dispatch("setAlert", alertData);
      window.scrollTo({ top: 0, behavior: "smooth" });
      return Promise.reject(error);
    }
  }
);

export default axiosInstance;
