export default {
  setCurrentGrowthplanLocally(state, growthplan) {
    if (growthplan != undefined) {
      state.currentActiveGrowthplan = growthplan;
    }
  },
  setGrowthplanSteps(state, growthplanSteps) {
    const newValue = growthplanSteps.map((x) => x.growthplan_step);
    state.growthplanSteps = newValue;
  },
  setCompletedQuestionID(state, completeQuestionData) {
    const correctRow =
      state.completedQuestions[completeQuestionData["questionId"]];
    this._vm.$set(correctRow, "completed", completeQuestionData["validated"]);
  },
  setFormCurrentQuestion(state, newCurrentQuestion) {
    state.currentQuestion = newCurrentQuestion;
  },

  setCompletedQuestions(state, value) {
    state.completedQuestions = value;
  },
  // Mutation that sets the current GrowthplanId based on the active state, there can always be only 1 active
  // growthplan.
  setCurrentGrowthplan(state, responseData) {
    if (Array.isArray(responseData)) {
      var activeGrowthplan = responseData
        .filter((growthplan) => {
          return growthplan.active === true;
        })
        .map((growthplan) => {
          return growthplan;
        });
      if (activeGrowthplan[0] != undefined) {
        state.currentActiveGrowthplan = activeGrowthplan[0];
      } else {
        state.currentActiveGrowthplan = {};
      }
    } else {
      state.currentActiveGrowthplan = {};
    }
  },
};
