export default {
  loggedIn(state) {
    return state.token !== null;
  },
  role(state) {
    return state.role;
  },
  token(state) {
    return state.token;
  },
};
