export default {
  setAlert(state, alert) {
    state.alert = alert;
  },
  setAlertVariant(state, alertVariant) {
    state.alertVariant = alertVariant;
  },
  setShowAlert(state, showAlert) {
    state.showAlert = showAlert;
  },
};
