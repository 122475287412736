<template>
  <div>
    <b-nav-item-dropdown
      toggle-class="d-flex align-items-center navbar-lang-dropdown"
      class="mr-4"
      right
    >
      <template #button-content>
        <CountryFlag
          :country="$t('country_code')"
          class="navbar-flag"
          data-test="selected-flag"
        />
        <span class="navbar-lang mr-1">{{
          $t("name_in_native_language")
        }}</span>
      </template>
      <b-dropdown-item
        v-for="lang in languages"
        :key="lang.language_code"
        link-class="d-flex align-items-center"
        @click="switchLocale(lang.language_code)"
        data-test="language-dropdown"
      >
        <CountryFlag
          :country="lang.country_code"
          class="navbar-flag"
          :data-test="'language-' + lang.language_code"
        />
        {{ lang.name_in_native_language }}
      </b-dropdown-item>
    </b-nav-item-dropdown>
  </div>
</template>

<script>
import CountryFlag from "vue-country-flag";

export default {
  name: "locale-changer",
  components: { CountryFlag },
  data() {
    return {
      languages: [],
    };
  },
  created() {
    /*
     * When localSwitcher is created, retrieve information about available
     * languages from the backend.
     */
    this.$store
      .dispatch("retrieveLanguages")
      .then((response) => {
        this.languages = response.data;
      })
      .catch(() => {
        this.languages = this.$i18n.messages;
      });
  },
  methods: {
    /**
     * Method that is triggered once a user clicks on a locale in the LocaleSwitcher.
     * Retrieves appropriate language from backend, depending on logged in state.
     *
     * @param: {String} locale - The locale that the user would like to switch to.
     */
    switchLocale(locale) {
      this.$store.dispatch("handleLoadingLang", locale).then(() => {
        if (this.$i18n.locale !== locale) {
          this.$store.dispatch("setSelectedLanguage", locale);
        }
      });
    },
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
  },
};
</script>
<style>
.navbar-flag {
  margin-right: 1px !important;
  align-self: baseline;
  opacity: 1; /* Normal state opacity */
  transition: opacity 0.1s ease;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.navbar-lang {
  font-family: "Lato", sans-serif;
}
.navbar-lang-dropdown:hover .navbar-flag {
  opacity: 0.8;
}
</style>
