import Vue from "vue";
import VueRouter from "vue-router";

import store from "@/store/index";

Vue.use(VueRouter);

const routes = [
  // General routes
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "@/views/Home.vue"),
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */
        "@/views/ChangePassword.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () =>
      import(
        /* webpackChunkName: "privacy-policy" */ "@/views/PrivacyPolicy.vue"
      ),
  },

  // Client Form routes
  {
    path: "/client/growthplan/start",
    name: "StartPage",
    component: () =>
      import(
        /* webpackChunkName: "growthplan-start-page" */ "@/views/client/growthplan/ClientGrowthplanStartPage.vue"
      ),
    meta: {
      requiresAuth: true,
      role: "User",
    },
  },
  {
    path: "/client/growthplan/details",
    name: "ClientCreateGrowthplan",
    component: () =>
      import(
        /* webpackChunkName: "client-growthplan-details" */ "@/views/client/growthplan/ClientCreateGrowthplan.vue"
      ),
    meta: {
      requiresAuth: true,
      role: "User",
    },
  },
  {
    path: "/client/growthplan",
    name: "ClientGrowthplanContainer",
    component: () =>
      import(
        /* webpackChunkName: "client-growthplan-container" */ "@/views/client/growthplan/ClientGrowthplanContainer.vue"
      ),
    meta: {
      requiresAuth: true,
      role: "User",
    },
  },
  {
    path: "/growthplan/all-todo",
    name: "AllTodo",
    component: () =>
      import(/* webpackChunkName: "all-todo" */ "@/views/AllTodo.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/client/general-feedback",
    name: "ClientGeneralFeedback",
    component: () =>
      import(
        /* webpackChunkName: "client-general-feedback" */ "@/components/growthplan/client/ClientGeneralFeedback.vue"
      ),
    meta: {
      requiresAuth: true,
      role: "User",
    },
  },

  // Client routes
  {
    path: "/client/login",
    name: "ClientLogin",
    component: () =>
      import(
        /* webpackChunkName: "client-login" */ /* webpackPrefetch: true */ "@/views/client/ClientLogin.vue"
      ),
  },
  {
    path: "/client/dashboard",
    name: "ClientDashboard",
    component: () =>
      import(
        /* webpackChunkName: "client-dashboard" */ /* webpackPrefetch: true */ "@/views/client/ClientDashboard.vue"
      ),
    meta: {
      requiresAuth: true,
      role: "User",
    },
  },
  {
    path: "/client/reset-password",
    name: "ClientResetPasswordEmail",
    component: () =>
      import(
        /* webpackChunkName: "client-reset-password-email" */ "@/views/client/ClientResetPasswordEmail.vue"
      ),
  },
  {
    path: "/client/reset-password/:token",
    name: "ClientResetPassword",
    component: () =>
      import(
        /* webpackChunkName: "client-reset-password-token" */ "@/views/client/ClientResetPassword.vue"
      ),
  },
  {
    path: "/client/create-password/:token",
    name: "ClientFirstLoginPasswordReset",
    component: () =>
      import(
        /* webpackChunkName: "client-first-login-reset" */ "@/views/client/ClientFirstLoginPasswordReset.vue"
      ),
  },

  // Consultant routes
  {
    path: "/consultant/login",
    name: "ConsultantLogin",
    component: () =>
      import(
        /* webpackChunkName: "consultant-login" */ /* webpackPrefetch: true */ "@/views/consultant/ConsultantLogin.vue"
      ),
  },
  {
    path: "/consultant/reset-password",
    name: "ConsultantResetPasswordEmail",
    component: () =>
      import(
        /* webpackChunkName: "consultant-reset-password-email" */ "@/views/consultant/ConsultantResetPasswordEmail.vue"
      ),
  },
  {
    path: "/consultant/reset-password/:token",
    name: "ConsultantResetPassword",
    component: () =>
      import(
        /* webpackChunkName: "consultant-reset-password-token" */ "@/views/consultant/ConsultantResetPassword.vue"
      ),
  },
  {
    path: "/consultant/create-password/:token",
    name: "ConsultantFirstLoginPasswordReset",
    component: () =>
      import(
        /* webpackChunkName: "consultant-first-login-reset" */ "@/views/consultant/ConsultantFirstLoginPasswordReset.vue"
      ),
  },
  {
    path: "/consultant/dashboard",
    name: "ConsultantDashboard",
    component: () =>
      import(
        /* webpackChunkName: "consultant-dashboard" */ /* webpackPrefetch: true */ "@/views/consultant/ConsultantDashboard.vue"
      ),
    meta: {
      requiresAuth: true,
      role: "Consultant",
    },
  },
  {
    path: "/consultant/client-registry",
    name: "ConsultantClientRegistry",
    component: () =>
      import(
        /* webpackChunkName: "consultant-client-registry" */ "@/views/consultant/ConsultantClientRegistry.vue"
      ),
    meta: {
      requiresAuth: true,
      role: "Consultant",
    },
  },
  {
    path: "/consultant/client-details/:id",
    name: "ConsultantClientDetails",
    component: () =>
      import(
        /* webpackChunkName: "consultant-client-details" */ "@/views/IndividualClientDetails.vue"
      ),
    meta: {
      requiresAuth: true,
      role: "Consultant",
    },
  },
  {
    path: "/consultant/client-edit-details/:id",
    name: "ConsultantClientEditDetails",
    component: () =>
      import(
        /* webpackChunkName: "consultant-client-edit-details" */ "@/views/IndividualClientEditDetails.vue"
      ),
    meta: {
      requiresAuth: true,
      role: "Consultant",
    },
  },

  // Consultant form routes
  {
    path: "/consultant/growthplan/feedback",
    name: "ConsultantGrowthplanContainer",
    component: () =>
      import(
        /* webpackChunkName: "consultant-growthplan-container" */ "@/views/consultant/growthplan/ConsultantGrowthplanContainer.vue"
      ),
    meta: {
      requiresAuth: true,
      role: "Consultant",
    },
  },

  // Admin routes

  {
    path: "/admin/login",
    name: "AdminLogin",
    component: () =>
      import(/* webpackChunkName: "admin-login" */ "@/views/admin/AdminLogin"),
  },
  {
    path: "/admin/dashboard",
    name: "AdminDashboard",
    component: () =>
      import(
        /* webpackChunkName: "admin-dashboard" */ "@/views/admin/AdminDashboard"
      ),
    meta: {
      requiresAuth: true,
      role: "Admin",
    },
  },
  {
    path: "/admin/growthplans",
    name: "AdminGrowthplansOverview",
    component: () =>
      import(
        /* webpackChunkName: "admin-growthplans-overview" */ "@/views/admin/AdminGrowthplanOverview.vue"
      ),
    meta: {
      requiresAuth: true,
      role: "Admin",
    },
  },
  {
    path: "/admin/consultants",
    name: "AdminConsultantsOverview",
    component: () =>
      import(
        /* webpackChunkName: "admin-consultants-overview" */ "@/views/admin/AdminConsultantAccountOverview.vue"
      ),
    meta: {
      requiresAuth: true,
      role: "Admin",
    },
  },
  {
    path: "/admin/clients",
    name: "AdminClientsOverview",
    component: () =>
      import(
        /* webpackChunkName: "admin-clients-overview" */ "@/views/admin/AdminClientAccountOverview.vue"
      ),
    meta: {
      requiresAuth: true,
      role: "Admin",
    },
  },
  {
    path: "/admin/client-details/:id",
    name: "AdminClientDetails",
    component: () =>
      import(
        /* webpackChunkName: "admin-client-details" */ "@/views/IndividualClientDetails.vue"
      ),
    meta: {
      requiresAuth: true,
      role: "Admin",
    },
  },
  {
    path: "/admin/client-edit-details/:id",
    name: "AdminClientEditDetails",
    component: () =>
      import(
        /* webpackChunkName: "admin-client-edit-details" */ "@/views/IndividualClientEditDetails.vue"
      ),
    meta: {
      requiresAuth: true,
      role: "Admin",
    },
  },
  {
    path: "/admin/consultant-edit-details/:id",
    name: "AdminConsultantEditDetails",
    component: () =>
      import(
        /* webpackChunkName: "admin-consultant-edit-details" */ "@/views/admin/AdminConsultantEditDetails.vue"
      ),
    meta: {
      requiresAuth: true,
      role: "Admin",
    },
  },
  {
    path: "/admin/edit-texts",
    name: "AdminTextEditor",
    component: () =>
      import(
        /* webpackChunkName: "admin-edit-texts" */ "@/views/admin/AdminTextEditor"
      ),
    meta: {
      requiresAuth: true,
      role: "Admin",
    },
  },
  {
    path: "/admin/view-growthplan",
    name: "AdminGrowthplanContainer",
    component: () =>
      import(
        /* webpackChunkName: "admin-growthplan-container" */ "@/views/admin/AdminGrowthplanContainer"
      ),
    meta: {
      requiresAuth: true,
      role: "Admin",
    },
  },
  {
    path: "/admin/consultant-registry",
    name: "AdminConsultantRegistry",
    component: () =>
      import(
        /* webpackChunkName: "admin-consultant-registry" */ "@/views/admin/AdminConsultantRegistry.vue"
      ),
    meta: {
      requiresAuth: true,
      role: "Admin",
    },
  },
  {
    path: "/share/growthplan/:code",
    name: "SharedGrowthplanContainer",
    component: () =>
      import(
        /* webpackChunkName: "share-growthplan-container" */ "@/views/shared/SharedGrowthplanContainer.vue"
      ),
  },

  // Error routes
  {
    path: "/404",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "not-found" */ "@/components/errors/404.vue"),
  },
  {
    path: "/500",
    name: "ServerError",
    component: () =>
      import(
        /* webpackChunkName: "server-error" */ "@/components/errors/500.vue"
      ),
  },
  // Catch all route which redirects to the 404 page
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});

/* Set a check to see if a route requires authentication and redirect to client/login
 * is user is not logged in.
 * Aditionally, retrieve language for that user on each navigation.
 */
router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters["loggedIn"]) {
      next({
        path: "/",
      });
    } else {
      if (to.matched.some((record) => record.meta.role)) {
        const record = to.matched.find((record) => record.meta.role);
        if (record.meta.role == store.getters["role"]) {
          await store.dispatch(
            "handleLoadingLang",
            store.getters.selectedLanguage
          );
          next();
        } else {
          next({
            path: "/",
          });
        }
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

export default router;
