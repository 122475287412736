<template>
  <div id="app">
    <transition name="load">
      <div v-if="!showLoading" id="loadedApp">
        <Navbar></Navbar>
        <b-container fluid class="app-container">
          <Alert></Alert>
          <router-view />
        </b-container>
        <Footer></Footer>
      </div>
      <LoadingOverlay v-else />
    </transition>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import Alert from "@/components/Alert";
import Footer from "@/components/Footer";
import LoadingOverlay from "@/components/LoadingOverlay";
import initValidation from "@/plugins/VeeValidate.js";

export default {
  name: "App",
  components: {
    Navbar,
    Alert,
    Footer,
    LoadingOverlay,
  },
  created() {
    /**
     * When app is created, initialise validation.
     * Also, retrieve the general languages, and if logged in, the languages
     * specific to the user role of the user.
     */
    initValidation(this, true);
    this.$store.dispatch("setupStore");
    this.$store.dispatch("handleLoadingLang", this.selectedLanguage);
  },
  computed: {
    selectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    showLoading() {
      return this.$store.getters.showLoading;
    },
  },
  methods: {
    updateFooterHeight() {
      const footer = this.$refs.footer; // Ensure your Footer component has ref="footer"
      if (footer) {
        const footerHeight = footer.offsetHeight; // Get the height of the footer
        document.documentElement.style.setProperty(
          "--footer-height",
          `${footerHeight}px`
        );
      }
    },
  },

  mounted() {
    this.updateFooterHeight(); // Set initial footer height
    window.addEventListener("resize", this.updateFooterHeight); // Adjust on resize
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.updateFooterHeight); // Clean up
  },
};
</script>
<style>
@import url(https://fonts.googleapis.com/css?family=Lato:400, 700, 900);

html,
body,
#app,
#loadedApp {
  height: 100%;
}

/* General styling used on multiple pages */
body {
  font-family: "Lato", sans-serif;
  color: #001b4d;
}
:root {
  --footer-height: 0px; /* Initial value */
}

.app-container {
  min-height: calc(100vh - var(--footer-height));
  padding-left: 5%;
  padding-right: 5%;
}

.hero-img {
  width: 75%;
}

.page-heading-h1 {
  font-size: 2.5rem;
  color: #0039a6;
  font-weight: 900;
}

@media (min-width: 576px) {
  .page-heading-h1 {
    font-size: 4rem;
    color: #0039a6;
  }
}

.h2-heading-description {
  font-size: 1.25rem;
  font-weight: 600;
}

@media (min-width: 576px) {
  .h2-heading-description {
    font-size: 1.5rem;
    font-weight: 600;
  }
}

.h3-company-description {
  color: #001b4d;
  font-size: 1.5rem;
  font-weight: bold;
}

@media (min-width: 576px) {
  .h3-company-description {
    color: #001b4d;
    font-size: 2.5rem;
  }
}

@media (max-width: 991px) {
  .partner-logo-column {
    margin-bottom: 5vh;
  }
}

.partner-logo-image {
  width: 100%;
}

.skeleton-margin-top {
  margin-top: 16px;
}

.button-filled:hover {
  background-color: #0039a6;
  border-color: #0039a6;
}

.button-filled {
  color: #fff !important;
  background-color: #ff896f;
  border: 3px solid #ff896f;
}

.button-not-filled:hover {
  background-color: #ff896f;
  border: 3px solid #ff896f;
  color: #fff !important;
}

.button-not-filled {
  background-color: transparent;
  border: 3px solid #ff896f;
  color: #ff896f !important;
}

.page-section {
  margin-top: 15vh;
}

.grey-help-text {
  font-size: 1rem;
  font-weight: bold;
  opacity: 0.4;
}

.page-section-heading {
  font-size: 2.25rem;
  font-weight: bold;
  color: #0039a6;
}

.table-row {
  font-size: 1.25rem;
  font-weight: bold;
}

.help-text {
  font-size: 1rem;
  font-weight: bold;
}

.welcome-section {
  margin-top: 20vh;
}

.divider-line {
  margin-top: 15vh;
}

@media (max-width: 767px) {
  .standard-text {
    font-size: 1rem;
  }
}

.page-section-sub-heading {
  margin-top: 50px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #0039a6;
}

@media (max-width: 991px) {
  .hero-left-column {
    margin-bottom: 10vh;
  }
}

@media (max-width: 991px) {
  .account-column {
    margin-top: 1rem;
  }
}

.nowrap-text {
  white-space: nowrap;
}

.standard-cancel-cross-icon {
  color: #e76e54;
}

/* Navbar styling */
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.app-footer {
  background-color: #0039a6;
  padding-left: 5vw;
  padding-right: 5vw;
  padding-top: 5vh;
  margin-top: 10vh;
}

.footer-heading {
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
}

.footer-copyright-heading {
  color: rgb(255, 255, 255);
  font-size: 0.75rem;
}

.footer-text {
  color: #fff;
}

.footer-list-item {
  color: #fff;
}

.footer-link {
  color: #fff;
}

.p-t-c {
  font-size: 0.75rem;
}

.footer-icon {
  color: #fff;
  font-size: 1.5rem;
}

.footer-logo-img {
  width: 90%;
}

/* Styling for the navbar */
.navbar {
  background-color: #0039a6;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff;
}

/* Transition Style */
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-property: height, opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.load-enter-active,
.load-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity, height;
  transition-timing-function: ease;
  overflow: hidden;
}

.load-enter,
.load-leave-active {
  opacity: 0;
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.5s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-2em, 0);
}

/* Page styling */
.bold-page-text {
  font-size: 1.25rem;
  font-weight: bold;
}

@media (min-width: 768px) {
  .bold-page-text {
    font-size: 1.5rem;
    font-weight: bold;
  }
}

.form-question-text {
  font-size: 1.25rem;
  font-weight: 900;
}

@media (min-width: 768px) {
  .form-question-text {
    font-size: 2rem;
  }
}

.form-question-explain-text {
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-weight: bold;
  color: rgba(0, 27, 77, 0.4);
}
.saving-text {
  margin-top: 1rem;
  font-weight: bold;
  color: rgba(0, 27, 77, 0.4);
}

.saving-text {
  margin-top: 1rem;
  font-weight: bold;
  color: rgba(0, 27, 77, 0.4);
}

@media (min-width: 768px) {
  .form-question-explain-text {
    font-size: 1.25rem;
  }
}

.form-question-div {
  border-style: solid;
  border-radius: 5px;
  border-color: #bfd0f1;
  padding: 1rem;
}

.form-feedback-heading {
  font-weight: bold;
  font-size: 1.5rem;
}

@media (max-width: 991px) {
  .to-do-row {
    margin-bottom: 15px;
  }
}

.switch-question-row {
  margin-top: 5px;
}

.feedback-name-span {
  font-size: 1rem;
  font-weight: bold;
}

.feedback-button {
  font-size: 0.875rem;
  height: 1.75rem;
  width: 5rem;
  padding-top: 3px;
  padding-bottom: 3px;
}

.feedback-div {
  margin-top: 2.5rem;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 5px;
}

.feedback-close-icon {
  color: #dc3545;
}

.feedback-accept-icon {
  color: #28a745;
}

@media (max-width: 991px) {
  .form-feedback-column {
    margin-top: 2rem;
  }
}

/* Styling for the matrix plugin */
.matrix-div-wrapper {
  position: relative;
  padding-top: 60%;
  width: 100%;
}

.matrix-image-div {
  background: url("./assets/matrix-growthplan.png") center / contain no-repeat;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.matrix-content-div {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  padding: 0.75rem;
}

.handle,
.vdr {
  position: absolute;
  box-sizing: border-box;
}

.vdr {
  touch-action: none;
}

/* Styling for the draggable plugin */
.form-draggable-list-div {
  cursor: inherit;
  background-color: #001b4d;
  padding: 0.25rem;
  line-height: 1rem;
  text-align: center;
  border-radius: 5px;
}

.form-draggable-list-span {
  color: rgb(255, 255, 255);
  font-size: 0.75rem;
}

.form-draggable-list-column {
  border-left: 1px solid #ced4da;
}

.form-draggable-list-heading {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  color: #ff896f;
}

.form-draggable-item-list {
  min-height: 100%;
}

.form-draggable-warning-text {
  color: #dc3545;
}

/* Form extra info styling */
.form-info-image {
  width: 100%;
}

.form-info-icon {
  cursor: pointer;
  color: var(--danger);
}

.standard-cancel-cross-icon {
  cursor: pointer;
}

.draggable {
  cursor: move;
}
</style>
