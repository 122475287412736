export default {
  currentActiveGrowthplan(state) {
    return state.currentActiveGrowthplan;
  },
  currentActiveGrowthplanId(state) {
    if (state.currentActiveGrowthplan != null) {
      return state.currentActiveGrowthplan.id;
    } else {
      return 0;
    }
  },
  currentGrowthplanActiveState(state) {
    return state.currentActiveGrowthplan.active;
  },
  currentGrowthplanSubmittedForFeedbackState(state) {
    return state.currentActiveGrowthplan.submitted_for_feedback;
  },
  currentGrowthplanCompletedState(state) {
    return state.currentActiveGrowthplan.completed;
  },
  currentGrowthplanCompany(state) {
    if (
      state.currentActiveGrowthplan.user != null &&
      state.currentActiveGrowthplan.user != undefined
    ) {
      return state.currentActiveGrowthplan.user.company;
    } else {
      return "";
    }
  },
  growthplanSteps(state) {
    return state.growthplanSteps;
  },
  completedQuestions(state) {
    return state.completedQuestions;
  },
  currentQuestion(state) {
    return state.currentQuestion;
  },
  growthplanLength(state) {
    return state.growthplanSteps.length;
  },
};
