<template>
  <div id="loading-overlay">
    <b-row class="vh-100">
      <b-col class="loading-col text-center">
        <span class="overlay-logo allign-middle">
          <b-img src="@/assets/ProGrowth-Logo-scaled.png" height="50px"></b-img>
          <b-spinner class="ml-4" variant="light"></b-spinner>
        </span>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "loadingOverlay",
};
</script>

<style>
.overlay-logo {
  background-color: #0039a6;
  border-radius: 5px;
  padding: 0.75em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading-col {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
