import axiosInstance from "@/axios";

export default {
  /**
   * Action that attempts to login as usertype User, retrieves token from api if login was correct,
   * receives an error if not.
   *
   * @param: {Vuex context} context - The current scoped context of the store.
   * @param: {Object} credentials - Credentials to log into the backend with.
   * @property: {String} credentials.email - Email to attempt a login with.
   * @property: {String} credentials.password - User password to attempt a login with.
   * @return: {Promise<Object>} data - Data from API.
   * @property: {String} data.access_token - Access token to authenticate user.
   * @property: {String} data.role - Role of the user, can be User, Consultant or Admin.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  retrieveToken(context, credentials) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/login/user", {
          email: credentials.email,
          password: credentials.password,
        })
        .then((response) => {
          const access_token = response.data.access_token;
          const role = response.data.role;

          // Set token in the auth module part of the store
          context.commit("setToken", access_token);
          context.commit("setRole", role);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Action that attempts to login as usertype Consultant, retrieves token from api if login was correct,
   * receives an error if not.
   *
   * @param: {Vuex context} context - The current scoped context of the store.
   * @param: {Object} credentials - Credentials to log into the backend with.
   * @property: {String} credentials.email - Email to attempt a login with.
   * @property: {String} credentials.password - User password to attempt a login with.
   * @return: {Promise<Object>} data - Data from API.
   * @property: {String} data.access_token - Access token to authenticate user.
   * @property: {String} data.role - Role of the user, can be User, Consultant or Admin.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  retrieveConsultantToken(context, credentials) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/login/consultant", {
          email: credentials.email,
          password: credentials.password,
        })
        .then((response) => {
          const access_token = response.data.access_token;
          const role = response.data.role;

          // Set token in the auth module part of the store
          context.commit("setToken", access_token);
          context.commit("setRole", role);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Action that attempts to login as usertype Admin, retrieves token from api if login was correct,
   * receives an error if not.
   *
   * @param: {Vuex context} context - The current scoped context of the store.
   * @param: {Object} credentials - Credentials to log into the backend with.
   * @property: {String} credentials.email - Email to attempt a login with.
   * @property: {String} credentials.password - User password to attempt a login with.
   * @return: {Promise<Object>} data - Data from API.
   * @property: {String} data.access_token - Access token to authenticate user.
   * @property: {String} data.role - Role of the user, can be User, Consultant or Admin.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  retrieveAdminToken(context, credentials) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/login/admin", {
          email: credentials.email,
          password: credentials.password,
        })
        .then((response) => {
          const access_token = response.data.access_token;
          const role = response.data.role;

          context.commit("setToken", access_token);
          context.commit("setRole", role);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Action that attempts to destroy the currently active access token from the api.
   *
   * @param: {Vuex context} context - The current scoped context of the store.
   * @return: {Promise}
   * @return: {Reject<Error>}
   */
  destroyToken(context) {
    if (context.getters["loggedIn"]) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .delete("/api/logout/access-token")
          .then((response) => {
            context.dispatch("resetAuthState");
            context.dispatch("handleResponse", response);
            resolve(response);
          })
          .catch((error) => {
            context.dispatch("resetAuthState");
            reject(error);
          });
      });
    }
    return false;
  },
  /**
   * Action that attempts a password reset for current user.
   *
   * @param: {Vuex context} context - The current scoped context of the store.
   * @param: {Object} passwords - Old, new and verification password stored in an Object
   * @property: {String} passwords.currentPassword - Password to attempt to authenticate with.
   * @property: {String} passwords.newPassword - New password to set after change password.
   * @property: {String} passwords.newPasswordVerification - New password again to verify the first one.
   * @return: {Promise<Object>} data - Data from API.
   * @property: {String} data.access_token - Access token to authenticate user.
   * @property: {String} data.role - Role of the user, can be User, Consultant or Admin.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  resetPassword(context, passwords) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/change-password", {
          current_password: passwords.currentPassword,
          new_password: passwords.newPassword,
          new_password2: passwords.newPasswordVerification,
        })
        .then((response) => {
          context.dispatch("handleResponse", response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Action that attempts to register a new Client.
   *
   * @param: {Vuex context} context - The current scoped context of the store.
   * @param: {Object} credentials - Data to initialise new User account with.
   * @return: {Promise<Object>}
   * @return: {Reject<Error>}
   */
  registerClient(context, credentials) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/register/user", {
          first_name: credentials.firstName,
          last_name: credentials.lastName,
          company: credentials.companyName,
          business_unit: credentials.businessUnit,
          email: credentials.email,
          phone_number: credentials.phone,
          address: credentials.street,
          zipcode: credentials.zipcode,
          city: credentials.city,
        })
        .then((response) => {
          context.dispatch("handleResponse", response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Action that attempts to register a new Consultant.
   *
   * @param: {Vuex context} context - The current scoped context of the store.
   * @param: {Object} credentials - Data to initialise new User account with.
   * @return: {Promise<Object>}
   * @return: {Reject<Error>}
   */
  registerConsultant(context, credentials) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/register/consultant", {
          first_name: credentials.firstName,
          last_name: credentials.lastName,
          email: credentials.email,
          phone_number: credentials.phone,
        })
        .then((response) => {
          context.dispatch("handleResponse", response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Action that attempts to update details of a Client account.
   *
   * @param: {Vuex context} context - The current scoped context of the store.
   * @param: {Object} credentials - Data to change Client account with.
   * @return: {Promise<Object>}
   * @return: {Reject<Error>}
   */
  updateClientDetails(context, credentials) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .put(`/api/user/${credentials.client_id}/update/details`, {
          first_name: credentials.firstName,
          last_name: credentials.lastName,
          company: credentials.companyName,
          business_unit: credentials.businessUnit,
          email: credentials.email,
          phone_number: credentials.phone,
          address: credentials.street,
          zipcode: credentials.zipcode,
          city: credentials.city,
        })
        .then((response) => {
          context.dispatch("handleResponse", response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateConsultantDetails(context, credentials) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .put(`/api/consultant/${credentials.consultant_id}/update/details`, {
          first_name: credentials.firstName,
          last_name: credentials.lastName,
          email: credentials.email,
          phone_number: credentials.phone,
        })
        .then((response) => {
          context.dispatch("handleResponse", response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  sendPasswordResetEmailUser(context, email) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/reset-password-email/user", {
          email: email,
        })
        .then((response) => {
          context.dispatch("handleResponse", response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  resetPasswordUser(context, credentials) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/reset-password/user/" + credentials.token, {
          new_password: credentials.new_password,
          new_password2: credentials.new_password2,
        })
        .then((response) => {
          context.dispatch("handleResponse", response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  resetPasswordUserFirstLogin(context, credentials) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/reset-password-first-time/user/" + credentials.token, {
          current_password: credentials.current_password,
          new_password: credentials.new_password,
          new_password2: credentials.new_password2,
        })
        .then((response) => {
          context.dispatch("handleResponse", response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  sendPasswordResetEmailConsultant(context, email) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/reset-password-email/consultant", {
          email: email,
        })
        .then((response) => {
          context.dispatch("handleResponse", response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  resetPasswordConsultant(context, credentials) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/reset-password/consultant/" + credentials.token, {
          new_password: credentials.new_password,
          new_password2: credentials.new_password2,
        })
        .then((response) => {
          context.dispatch("handleResponse", response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  resetPasswordConsultantFirstLogin(context, credentials) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(
          "/api/reset-password-first-time/consultant/" + credentials.token,
          {
            current_password: credentials.current_password,
            new_password: credentials.new_password,
            new_password2: credentials.new_password2,
          }
        )
        .then((response) => {
          context.dispatch("handleResponse", response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
