const getDefaultState = () => {
  return {
    benefitListAnswers: [],
    customerMatrix: [],
    customerTodoAnswers: [],
    productMatrix: [],
    productTodoAnswers: [],
    salesChannelsTodoAnswers: [],
    salesChannelsListAnswers: {
      main_channel_answers: [],
      support_channel_answers: [],
      unused_channel_answers: [],
    },
    leadToolsAnswers: [],
    followerToolsAnswers: [],
    leadFollowerTodoAnswers: [],
    peopleTodoAnswers: [],
    fundingTodoAnswers: [],
  };
};

export default {
  // Mutation to change data of question by storeDataName.
  setFormAnswerData(state, answerData) {
    this._vm.$set(
      state.userAnswers[answerData.growthplanId],
      answerData.storeDataName,
      answerData.data
    );
  },
  setNestedFormAnswerData(state, answerData) {
    this._vm.$set(
      state.userAnswers[answerData.growthplanId][answerData.storeDataName],
      answerData.nested,
      answerData.data
    );
  },
  resetAllQuestionsState(state) {
    this._vm.$set(state, "userAnswers", {});
  },
  resetQuestionsState(state, growthplanId) {
    this._vm.$set(state.userAnswers, growthplanId, getDefaultState());
  },
  // Initialise state of Growth Plan questions
  initGrowthplanQuestionState(state, growthplanId) {
    if (state.userAnswers[growthplanId] == undefined) {
      this._vm.$set(state.userAnswers, growthplanId, getDefaultState());
    }
  },
  // Cleans answers from localStorage that are not in growthplans array
  cleanAnswers(state, growthplans) {
    let ids = growthplans.map((growthplan) => {
      return growthplan.id;
    });

    let storedIds = Object.keys(state.userAnswers);

    storedIds.forEach((storeId) => {
      if (ids.indexOf(parseInt(storeId)) == -1) {
        this._vm.$delete(state.userAnswers, storeId);
      }
    });
  },
  setSavingAnswer(state, saving) {
    state.savingAnswer = saving;
  },
  setQuestionLastSaved(state, date) {
    state.questionLastSaved = date;
  },
};
