import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

export default {
  state: () => ({
    userAnswers: {},
    savingAnswer: false,
    questionLastSaved: null,
  }),
  getters,
  mutations,
  actions,
};
