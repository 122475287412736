import { library } from "@fortawesome/fontawesome-svg-core";
import { faFacebook, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import {
  faStar,
  faClock,
  faClipboard,
  faCheckCircle,
  faTrashAlt,
  faEye,
  faEyeSlash,
  faCopy,
  faQuestionCircle,
} from "@fortawesome/free-regular-svg-icons";

import {
  faStar as faStarSolid,
  faCheckCircle as faCheckCircleSolid,
  faCheck,
  faArrowDown,
  faEdit,
  faTimes,
  faExclamationCircle,
  faEllipsisH,
  faItalic,
  faBold,
  faUnderline,
  faStrikethrough,
  faListOl,
  faListUl,
  faSort,
  faSortUp,
  faSortDown,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";

export default function () {
  library.add(
    faStar,
    faStarSolid,
    faFacebook,
    faLinkedin,
    faClock,
    faClipboard,
    faCheckCircle,
    faCheckCircleSolid,
    faCheck,
    faArrowDown,
    faEye,
    faEyeSlash,
    faEdit,
    faTimes,
    faTrashAlt,
    faExclamationCircle,
    faEllipsisH,
    faItalic,
    faBold,
    faUnderline,
    faStrikethrough,
    faCopy,
    faListOl,
    faListUl,
    faSort,
    faSortUp,
    faSortDown,
    faFilter,
    faQuestionCircle
  );
}
