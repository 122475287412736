import axiosInstance from "@/axios";
import { i18n } from "@/plugins/i18n";

export default {
  retrieveLanguages() {
    /**
     * Retrieves available languages in the backend.
     */
    return axiosInstance.get("/api/languages/get/all");
  },
  /**
   * Handles the loading of languages, based on the state of the store.
   * @param: {Vuex context} context - The current scoped context of the store.
   * @param: {String} lang - Language string to retrieve, e.g. 'fi', 'en'.
   */
  async handleLoadingLang(context, lang) {
    const loadedGeneral = context.getters.loadedLangGeneral;
    const loadedForMe = context.getters.loadedLangForMe;
    const loggedIn = context.getters.loggedIn;
    const selectedLanguage = context.getters.selectedLanguage;
    const countingDuration = context.getters.countingDuration;

    var reqs = [];
    // Only load current language if it is not loaded yet.
    if (selectedLanguage === lang) {
      if (!loadedGeneral) {
        reqs.push(context.dispatch("retrieveGeneralLanguage", lang));
      }
      // Only load user type specific languages if user is logged in.
      if (!loadedForMe && loggedIn) {
        reqs.push(context.dispatch("retrieveLanguageForMe", lang));
      }
      // If the language is different, always load a new language.
    } else {
      reqs.push(context.dispatch("retrieveGeneralLanguage", lang));
      // Again, only load user type specific languages if user is logged in.
      if (loggedIn) {
        reqs.push(context.dispatch("retrieveLanguageForMe", lang));
      }
    }

    // Only if there is anything to load
    if (reqs.length !== 0) {
      // Set show loading variable to true
      context.dispatch("setShowLoading", true);
      reqs.push(
        new Promise((resolve) => setTimeout(resolve, countingDuration))
      );
      new Promise((resolve, reject) => {
        // Make sure all requests are retrieved
        Promise.all(reqs)
          .then((response) => {
            context.dispatch("setShowLoading", false);
            resolve(response);
          })
          // If error occured, disable loading screen and reject error.
          .catch((error) => {
            context.dispatch("setShowLoading", false);
            reject(error);
          });
        // If any error occured, handle it.
      }).catch((error) => {
        context.dispatch("handleError", error);
      });
    }
  },
  /**
   * Retrieves general languages, strings that all users should be able to see.
   *
   * @param: {Vuex context} context - The current scoped context of the store.
   * @param: {String} lang - Language string to retrieve, e.g. 'fi', 'en'.
   */
  retrieveGeneralLanguage(context, lang) {
    context.commit("setLoadedLangGeneral", false);
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/languages/get/${lang}/general`)
        .then((response) => {
          let messages = response.data;
          // Set current messages for the language
          i18n.mergeLocaleMessage(lang, messages);

          context.commit("setLoadedLangGeneral", true);
          resolve(response);
        })
        .catch((error) => {
          context.commit("setLoadedLangGeneral", true);
          reject(error);
        });
    });
  },
  /**
   * Retrieves languages for the user with the current user type.
   *
   * @param: {Vuex context} context - The current scoped context of the store.
   * @param: {String} lang - Language string to retrieve, e.g. 'fi', 'en'.
   */
  retrieveLanguageForMe(context, lang) {
    context.commit("setLoadedLangForMe", false);
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/languages/get/${lang}/for-me`)
        .then((response) => {
          let messages = response.data;
          i18n.mergeLocaleMessage(lang, messages);
          context.commit("setLoadedLangForMe", true);
          resolve(response);
        })
        .catch((error) => {
          context.commit("setLoadedLangForMe", true);
          reject(error);
        });
    });
  },
  /**
   * Retrieves languages for needed to display a shared Growthplan
   *
   * @param: {Vuex context} context - The current scoped context of the store.
   * @param: {Object} sharedLangData - Object containing data to retrieve shared languages.
   * @property: {String} lang - Lang code to retrieve.
   * @property: {String} shareCode - Share code of which to pull data.
   */
  retrieveSharedLang(context, sharedLangData) {
    context.commit("setLoadedLangForMe", false);
    const lang = sharedLangData.lang;
    const shareCode = sharedLangData.shareCode;

    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/shared/get/language/${shareCode}/${lang}`)
        .then((response) => {
          let messages = response.data;
          i18n.mergeLocaleMessage(lang, messages);
          context.commit("setLoadedLangForMe", true);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Set the language according to a certain lang string.
   *
   * @param: {Vuex context} context - The current scoped context of the store.
   * @param: {String} lang - Language string to retrieve, e.g. 'fi', 'en'.
   */
  setSelectedLanguage(context, lang) {
    // Make sure the variable in the store is updated
    context.commit("setSelectedLanguage", lang);

    // Chance the locale in the i18n instance
    i18n.locale = lang;

    // Make sure axios is aware of the language
    axiosInstance.defaults.headers.common["Accept-Language"] = lang;
  },
  /**
   * Retrieve languages by category, for the admin dashboard text editor.
   *
   * @param: {Vuex context} context - The current scoped context of the store.
   * @param: {Object} langData - Object containing information about what to language and category retrieve.
   * @property: {String} lang - Language string to retrieve, e.g. 'fi', 'en'.
   * @property: {String} category - What language category to retrieve.
   */
  retrieveLanguageMessagesByCategory(context, langData) {
    const lang = langData.lang;
    const category = langData.category;
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/languages/get/${category}/${lang}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Let Admin save new language messages.
   *
   * @param: {Vuex context} context - The current scoped context of the store.
   * @param: {Object} messagesData - Messages to update.
   */
  saveLanguageMessages(context, messagesData) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`/api/languages/post/messages`, messagesData)
        .then((response) => {
          context.dispatch("handleResponse", response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Let Admin reset languages to their default.
   *
   * @param: {Vuex context} context - The current scoped context of the store.
   * @param: {Object} messagesData - Messages to update.
   */
  resetLanguageMessages(context, messagesData) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`/api/languages/reset/messages`, messagesData)
        .then((response) => {
          context.dispatch("handleResponse", response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  setShowLoading(context, loading) {
    context.commit("setShowLoading", loading);
  },
  setCountingDuration(context, duration) {
    context.commit("setCountingDuration", duration);
  },
  // Set general language loaded for loading state of skeleton loaders
  setLoadedLangGeneral(context, loaded) {
    context.commit("setLoadedLangGeneral", loaded);
  },
  // Set language for me loaded for loading state of skeleton loaders
  setLoadedLangForMe(context, loaded) {
    context.commit("setLoadedLangForMe", loaded);
  },
};
