import axiosInstance from "@/axios";

export default {
  updateCompletedQuestionID(context, completeQuestionData) {
    context.commit("setCompletedQuestionID", completeQuestionData);
  },
  updateFormCurrentQuestion(context, currentQuestion) {
    context.commit("setFormCurrentQuestion", currentQuestion);
  },
  updateCurrentGrowthplan(context, growthplan) {
    context.commit("setCurrentGrowthplanLocally", growthplan);
    context.dispatch("clearFeedbackLastSaved");
    context.dispatch("clearQuestionLastSaved");
  },
  /**
   * Action that attemps to save the Growthplan progress percentage.
   *
   * @param: {Vuex context} context - The current context of the store.
   * @param: {Object} percentageData - Data containing properties needed to update percentage.
   * @property: {Number} percentageData.percentage - The percentage that the growthplan is completed.
   * @property: {Number} percentageData.growthplanId - The growthplan ID to update percentage from.
   * @return: {Promise<Object>} Response - Response from api in case of correct storage of data.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  saveGrowthplanPercentProgress(context, percentageData) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .patch(
          `/api/growthplan/${percentageData.growthplanId}/save/growthplan-progress`,
          {
            percent_completed: percentageData.percentCompleted,
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Action that attemps to create a new Growthplan details. The API creates a new Growthplan for the user with these
   * details and returns the growthplan ID.
   *
   * @param: {Vuex context} context - The current context of the store.
   * @param: {Object} growthplanDetails - Data containing properties needed to update growthplanDetails.
   * @property: {Number} growthplanDetails.name - User inputted name for the GrowthPlan.
   * @property: {Number} growthplanDetails.businessUnit - User inputted business unit for the GrowthPlan.
   * @return: {Promise<Object>} Response - Response from api in case of correct storage of data.
   * @property: {Object} Response.data.growthplan_id - GrowthPlan ID of newly generated GrowtPlan.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  createNewGrowthplan(context, growthplanDetails) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/growthplan/create/new-growthplan", {
          name: growthplanDetails.name,
          business_unit: growthplanDetails.businessUnit,
          copy_from_previous: growthplanDetails.copyFromPrevious,
          growthplan_to_copy: growthplanDetails.growthplanToCopy,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Action that attemps to save the state of the completed questions.
   *
   * @param: {Vuex context} context - The current context of the store.
   * @param: {Number} growthplanId - The growthplan ID to get growthplan steps of the correct growthplan.
   * @return: {Promise<Object>} Response - Response from the API with growthplanSteps data.
   * @property: {Object} Response.data - GrowthPlanStep data.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  saveCompletedQuestions(context, answerData) {
    const baseURL = `/api/growthplan/${answerData.growthplanId}/save/completed-questions`;
    return new Promise((resolve, reject) => {
      axiosInstance
        .put(baseURL, answerData.data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Action that attemps to retrieve steps of the growthplan.
   *
   * @param: {Vuex context} context - The current context of the store.
   * @param: {Number} growthplanId - The growthplan ID to get growthplan steps of the correct growthplan.
   * @return: {Promise<Object>} Response - Response from the API with growthplanSteps data.
   * @property: {Object} Response.data - GrowthPlanStep data.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  retrieveCompletedQuestions(context, growthplanId) {
    const baseURL = `/api/growthplan/${growthplanId}/get/completed-questions`;
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(baseURL)
        .then((response) => {
          const growthplanSteps = response.data;
          const sortedGrowthplanSteps = growthplanSteps.sort((a, b) => {
            return a.growthplan_step.position - b.growthplan_step.position;
          });
          context.commit("setGrowthplanSteps", sortedGrowthplanSteps);
          context.commit("setCompletedQuestions", sortedGrowthplanSteps);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Action that attemps to retrieve steps of the shared growthplan.
   *
   * @param: {Vuex context} context - The current context of the store.
   * @param: {Number} growthplanId - The growthplan ID to get growthplan steps of the correct growthplan.
   * @return: {Promise<Object>} Response - Response from the API with growthplanSteps data.
   * @property: {Object} Response.data - GrowthPlanStep data.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  retrieveSharedGrowthplanSteps(context, shareCode) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/shared/get/growthplan-steps/${shareCode}`)
        .then((response) => {
          const growthplanSteps = response.data;
          const sortedGrowthplanSteps = growthplanSteps.sort((a, b) => {
            return a.growthplan_step.position - b.growthplan_step.position;
          });
          context.commit("setGrowthplanSteps", sortedGrowthplanSteps);
          context.commit("setCompletedQuestions", sortedGrowthplanSteps);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Action that attemps to retrieve shared growthplan.
   *
   * @param: {Vuex context} context - The current context of the store.
   * @param: {Number} growthplanId - The growthplan ID to get growthplan steps of the correct growthplan.
   * @return: {Promise<Object>} Response - Response from API confirming operation was succesfull.
   * @property: {Object} Response.data - GrowthPlanStep data.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  retrieveSharedGrowthplan(context, shareCode) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/shared/get/growthplan/${shareCode}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Action that attemps to retrieve all GrowthPlans of a user. Then dispatches an action to set the active growthplan
   * as active in the store and local storage.
   *
   * @param: {Vuex context} context - The current context of the store.
   * @return: {Promise<Object>} Response - Response from the API with growthplanSteps data.
   * @property: {Object} Response.data - All growthplans of user who submitted the request.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  retrieveUserGrowthplans(context) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/growthplan/get/growthplans")
        .then((response) => {
          context.commit("setCurrentGrowthplan", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Action that attempts to retrieve a share link for the Growth Plan by growthplanId
   *
   * @param: {Vuex context} context - The current context of the store.
   * @param: {growthplanId} Number - The ID of the growthplan to toggle the shared state of.
   * @return: {Promise<Object>} Response - Response from the API with growthplanSteps data.
   * @property: {Object} Response.data - Link from which to access the shared Growth Plan.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  retrieveGrowthplanShareLink(context, growthplanId) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/shared/get/growthplan/sharelink/${growthplanId}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Action that attemps to toggle the share state of the growthplan.
   *
   * @param: {Vuex context} context - The current context of the store.
   * @param: {growthplaId} Number - The ID of the growthplan to toggle the shared state of.
   * @return: {Promise<Object>} Response - Response from the API containing confirmation of action.
   * @property: {Object} Response.data - Message that confirms Growth Plan shard state was successfully toggled.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  toggleGrowthplanShared(context, growthplanId) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .patch(`/api/shared/toggle/growthplan/${growthplanId}/shared`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Action that attemps to set a GrowthPlan's state in the server to submiited for feedback.
   *
   * @param: {Vuex context} context - The current context of the store.
   * @property: {Number} growthplanId - GrowthPlan ID to update data from.
   * @return: {Promise<Object>} Response - Response from the API with confirmation of action.
   * @property: {String} Response.data.msg - Message from server in case succesfull.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   * @property: {String} error.data.msg - Message from server in case unsuccesfull.
   */
  setSubmittedForFeedback(context, growthplanId) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .put(`/api/growthplan/${growthplanId}/save/submit-for-feedback`)
        .then((response) => {
          context.dispatch("handleResponse", response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Action that attemps to set a GrowthPlan's state to completed, meaning the consultant has given feedback.
   *
   * @param: {Vuex context} context - The current context of the store.
   * @property: {Number} growthplanId - GrowthPlan ID to update data from.
   * @return: {Promise<Object>} Response - Confirmation response from API.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  setGrowthplanCompleted(context, growthplanId) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .put(`/api/growthplan/${growthplanId}/save/completed`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Attempts to retrieve all Todo questions.
   *
   * @param: {Vuex context} context - The current context of the store.
   * @return: {Promise<Object>} Response - Response from api in case of retrieval of data.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  retrieveAllTodo(context, growthplanId) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/growthplan/${growthplanId}/questions/get/all-todo`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Attempts to PUT new done state for a Todo question to the API.
   *
   * @param: {Vuex context} context - The current context of the store.
   * @param: {Object} payload - Data needed to make valid request.
   * @return: {Promise<Object>} Response - Response from api in case of retrieval of data.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  saveDone(context, payload) {
    const growthplanId = payload.growthplanId;
    const stepId = payload.stepId;
    const questionId = payload.questionId;
    const done = payload.done;

    return new Promise((resolve, reject) => {
      axiosInstance
        .patch(
          `/api/growthplan/${growthplanId}/question/${stepId}/${questionId}/save/done`,
          {
            done: done,
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
