import axiosInstance from "@/axios";

export default {
  /**
   * Action that attemps to save growthplan question answers to the database.
   *
   * @param: {Vuex context} context - The current scoped context of the store.
   * @param: {Object} anserData - Contains information to make correct api call for correct growthplan.
   * @property: {String} answerData.urlParameter - String that gets added to url to ensure retrieval of correct data.
   * @property: {Number} answerData.growthPlanId - ID of the growthplan to retrieve data from.
   * @property: {Object} answerData.data - Answers to this question to send to the API.
   * @return: {Promise<Object>} Response - Response from API in case of succesfull storage.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  saveAnswer(context, answerData) {
    const baseURL =
      `/api/growthplan/${answerData.growthplanId}/questions/` +
      answerData.urlParameter;
    context.dispatch("setSavingAnswer", true);
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(baseURL, answerData.data)
        .then((response) => {
          context.dispatch("setSavingAnswer", false);
          context.dispatch("setQuestionLastSaved");
          resolve(response);
        })
        .catch((error) => {
          context.dispatch("setSavingAnswer", false);
          reject(error);
        });
    });
  },
  /**
   * Action that attemps to retrieve growthplan question answers from the database.
   *
   * @param: {Vuex context} context - The current scoped context of the store.
   * @param: {Object} questionData - Contains information to make correct api call for correct growthplan.
   * @property: {String} questionData.urlParameter - String that gets added to url to ensure retrieval of correct data.
   * @property: {Number} questionData.growthPlanId - ID of the growthplan to retrieve data from.
   * @return: {Promise<Object>} Response.data - Data from API, properties of reponse depend on data that the
   * question needs.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */

  retrieveAnswer(context, questionData) {
    const baseURL =
      `/api/growthplan/${questionData.growthplanId}/questions/` +
      questionData.urlParameter;
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(baseURL)
        .then((response) => {
          const answerData = {
            data: response.data,
            storeDataName: questionData.storeDataName,
            growthplanId: questionData.growthplanId,
          };
          context.commit("setFormAnswerData", answerData);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Action that attemps to delete a specific answer from the database.
   *
   * @param: {Vuex context} context - The current scoped context of the store.
   * @param: {Object} questionData - Contains information to make correct api call for correct growthplan.
   * @property: {String} questionData.urlParameter - String that gets added to url to ensure retrieval of correct data.
   * @property: {Number} questionData.growthPlanId - ID of the growthplan to retrieve data from.
   * @return: {Promise<Object>} Response.data - Data from API, properties of reponse depend on data that the
   * question needs.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  deleteAnswer(context, removeData) {
    const baseURL =
      `/api/growthplan/${removeData.growthplanId}/questions/` +
      removeData.urlParameter +
      `/${removeData.id}`;
    context.dispatch("setSavingAnswer", true);
    return new Promise((resolve, reject) => {
      axiosInstance
        .delete(baseURL)
        .then((response) => {
          context.dispatch("setSavingAnswer", false);
          context.dispatch("setQuestionLastSaved");
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Actions that set values of different arrays of the SalesChannelList components.
   *
   * @param: {Vuex context} context - The current scoped context of the store.
   * @param: {Array} value - Array containing updated elements of the main, support, unused or selection array.
   */
  setNestedFormAnswerData(context, data) {
    context.commit("setNestedFormAnswerData", data);
  },
  resetQuestionsState(context, growthplanId) {
    context.commit("resetQuestionsState", growthplanId);
    context.commit("setFormCurrentQuestion", 0);
  },
  initGrowthplanQuestionState(context, growthplan_id) {
    context.commit("initGrowthplanQuestionState", growthplan_id);
  },
  updateFormAnswerData(context, answerData) {
    context.commit("setFormAnswerData", answerData);
  },
  cleanAnswers(context, growthplans) {
    context.commit("cleanAnswers", growthplans);
  },
  resetAllQuestionsState(context) {
    context.commit("resetAllQuestionsState");
  },
  setSavingAnswer(context, saving) {
    context.commit("setSavingAnswer", saving);
  },
  setQuestionLastSaved(context) {
    context.commit("setQuestionLastSaved", Date.now());
  },
  clearQuestionLastSaved(context) {
    context.commit("setQuestionLastSaved", null);
  },
};
