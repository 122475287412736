import axiosInstance from "@/axios";

export default {
  /**
   * Attempts to retrieve the user profile.
   *
   * @param: {Vuex context} context - The current context of the store.
   * @return: {Promise<Object>} Response - Response from api in case of correct storage of data.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  retrieveUserProfile(context) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/user/get/profile")
        .then((response) => {
          context.commit("setUserData", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Attempts to retrieve if the user is allowed to make a new Growth Plan
   *
   * @param: {Vuex context} context - The current context of the store.
   * @return: {Promise<Object>} Response - Response from api in case of retrieval of data.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  retrieveAllowedNewGrowthplans() {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/user/get/allowed-new-growthplan")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteUserAccount(context, email) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .delete(`/api/user/${email}/delete`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
