export default {
  feedback(state) {
    return state.feedback;
  },
  savingFeedback(state) {
    return state.savingFeedback;
  },
  feedbackLastSaved(state) {
    return state.feedbackLastSaved;
  },
};
