// i18n package for language switching
import { i18n } from "./plugins/i18n.js";
import CountryFlag from "vue-country-flag";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import initFontAwesome from "@/plugins/FontAwesome";

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

// Bootstrap-vue package
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import VueResize from "vue-resize";

// Import bootstrap and bootstrap-vue css files
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-resize/dist/vue-resize.css";

// Import Vue libraries after external libraries to make sure CSS is not overwritten by bootstrap-vue css
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// Allow boostrap to use BootstrapVue and the IconsPlugin
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueResize);

// Function to init individual icons from Font Awesome.
initFontAwesome();

if (!process.env.NODE_ENV === "development") {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    tracingOptions: {
      trackComponents: true,
    },
    environment: process.env.NODE_ENV,
  });
}

// Set the FontAwesome package as a global component
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("country-flag", CountryFlag);

Vue.config.productionTip = false;

// Create a new instance of the Vue App
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
