import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

// Default state for loading variables
const getDefaultState = () => {
  return {
    loadedLangGeneral: false,
    loadedLangForMe: false,
    showLoading: false,
    countingDuration: 500,
  };
};

// Mutation that resets loading variables for languages in store.
mutations.setDefaultLanguages = (state) => {
  Object.assign(state, getDefaultState());
};

// Set selectedLanguage in store normally, so setDefaultLanguages won't reset it.
// Done so language preference is stored in localStorage.
const state = {
  selectedLanguage: "en",
  loadedLangGeneral: false,
  loadedLangForMe: false,
  showLoading: false,
  countingDuration: 500,
};

export default {
  state,
  getters,
  mutations,
  actions,
};
