<template>
  <div>
    <b-alert
      class="mt-5"
      :show="dismissCountDown"
      fade
      dismissible
      @dismissed="hideAlert"
      :variant="alertData.alertVariant"
      @dismiss-count-down="countDownChanged"
      data-test="alert"
    >
      {{ alertData.alert }}
    </b-alert>
  </div>
</template>
<script>
export default {
  /**
   * Alert to show to user in case of interaction with backend.
   * @displayName Alert
   */
  name: "Alert",
  data() {
    return {
      /**
       * Used as a counter to count down from countDownLength to 0.
       */
      dismissCountDown: 0,
      /**
       * Amount of seconds to show each alert.
       */
      countDownLength: 5,
    };
  },
  methods: {
    hideAlert() {
      /**
       * Hide alert locally and dispatch action to set correct fields in the store.
       */
      this.dismissCountDown = 0;
      this.$store.dispatch("hideAlert");
    },
    /**
     * Handels counting down.
     */
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
  },
  computed: {
    /**
     * Retrieves data to show in alert from store.
     */
    alertData() {
      return this.$store.getters.alertData;
    },
    /**
     * Retrieves from store if alert should be shown or not.
     */
    showAlert() {
      return this.$store.getters.showAlert;
    },
  },
  watch: {
    /**
     * Watches the showAlert var, if it changes to true, init timer to show alert for countDownLength amount of secs.
     * If it changes to false, hide the alert.
     */
    showAlert: function (newVal) {
      if (newVal) {
        this.dismissCountDown = this.countDownLength;
      } else {
        this.hideAlert();
      }
    },
  },
};
</script>
