import axiosInstance from "@/axios";

export default {
  /**
   * Action that attempts to save all feedback given by a consultant per growthplan step.
   *
   * @param: {Vuex context} context - The current context of the store.
   * @param: {Object} feedbackData - Data needed to store the feedback data.
   * @property: {Number} feedbackData.growthplan_id - GrowthPlan ID to save all feedback to.
   * @property: {Object} feedbackdata.feedback_data - All feedback in an object to store in API.
   * @return: {Promise<Object>} response - Data from API in case of succesfull save.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  saveAllFeedback(context, feedbackData) {
    const baseURL = `/api/growthplan/${feedbackData.growthplan_id}/save/feedback/all`;
    context.dispatch("setSavingFeedback", true);
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(baseURL, feedbackData.feedback_data)
        .then((response) => {
          context.dispatch("setSavingFeedback", false);
          context.dispatch("setFeedbackLastSaved");
          resolve(response);
        })
        .catch((error) => {
          context.dispatch("setSavingFeedback", false);
          reject(error);
        });
    });
  },
  saveFeedback(context, feedbackData) {
    const baseURL = `/api/growthplan/${feedbackData.growthplan_id}/save/feedback`;
    context.dispatch("setSavingFeedback", true);
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(baseURL, feedbackData.feedback_data)
        .then((response) => {
          context.dispatch("setSavingFeedback", false);
          context.dispatch("setFeedbackLastSaved");
          resolve(response);
        })
        .catch((error) => {
          context.dispatch("setSavingFeedback", false);
          reject(error);
        });
    });
  },
  updateFeedback(context, feedbackData) {
    const baseURL = `/api/growthplan/${feedbackData.growthplan_id}/save/feedback`;
    context.dispatch("setSavingFeedback", true);
    return new Promise((resolve, reject) => {
      axiosInstance
        .patch(baseURL, feedbackData.feedback_data)
        .then((response) => {
          context.dispatch("setSavingFeedback", false);
          context.dispatch("setFeedbackLastSaved");
          resolve(response);
        })
        .catch((error) => {
          context.dispatch("setSavingFeedback", false);
          reject(error);
        });
    });
  },
  /**
   * Action that attempts to retrieve all feedback given by a consultant per growthplan step and puts it in the store.
   *
   * @param: {Vuex context} context - The current context of the store.
   * @param: {Number} growthplanId - GrowthPlan ID to get all feedback from.
   * @return: {Promise<Object>} response.data - Data from API in case of succesfull retrieval of all feedback data.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  retrieveFeedback(context, growthplanId) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/growthplan/${growthplanId}/get/feedback/all`)
        .then((response) => {
          let feedbackData = [];
          if (Array.isArray(response.data)) {
            feedbackData = response.data;
          }
          context.commit("setFeedback", feedbackData);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Action that attempts to delete feedback by growthplan and feedback id.
   *
   * @param: {Vuex context} context - The current context of the store.
   * @param: {Number} growthplanId - GrowthPlan ID to get all feedback from.
   * @return: {Promise<Object>} response.data - Data from API in case of succesfull retrieval of all feedback data.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  deleteFeedback(context, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .delete(
          `/api/growthplan/${payload.growthplanId}/delete/feedback/${payload.feedbackId}`
        )
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Action that commits mutation to change the current state of the feedback Object in the Vuex store.
    )*
   *
   * @param: {Vuex context} context - The current context of the store.
   * @param: {Object} newFeedback - New feedback to change the store with.
   */
  setFeedback(context, newFeedback) {
    context.commit("setFeedback", newFeedback);
  },
  /**
   * Action that attempts to save the General Feedback of the consultant.
   *
   * @param: {Vuex context} context - The current context of the store.
   * @param: {Object} generalFeedbackData - Data needed to store generalFeedbackData.
   * @property: {String} generalFeedbackData.generalFeedbackText - General feedback text to store in API.
   * @property: {Number} generalFeedbackData.growthplan_id - GrowthPlan ID to save general feedback to.
   * @return: {Promise<Object>} response - Data from API in case of succesfull save.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  saveGeneralFeedback(context, generalFeedbackData) {
    const baseURL = `/api/growthplan/${generalFeedbackData.growthplan_id}/save/general-feedback`;
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(baseURL, {
          general_feedback_data: generalFeedbackData.generalFeedbackText,
        })
        .then((response) => {
          context.dispatch("handleResponse", response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Action that commits mutation to change the current state of the general feedback in the Vuex store.
   *
   *
   * @param: {Vuex context} context - The current context of the store.
   * @param: {String} newGeneralFeedback - New general feedback to change the store with.
   */
  setGeneralFeedback(context, newGeneralFeedback) {
    context.commit("setGeneralFeedback", newGeneralFeedback);
  },
  /**
   * Action that attempts to retrieve general feedback given by a consultant.
   *
   * @param: {Vuex context} context - The current context of the store.
   * @param: {Object} generalFeedbackData - Data needed to store the feedback data.
   * @property: {Number} generalFeedbackData.growthplan_id - GrowthPlan ID to save all feedback to.
   * @return: {Promise<Object>} response.data - Data from API in case of succesfull retrieval.
   * @property: {String} response.data;feedback - General feedback text from API in case of succesfull retrieval.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  retrieveGeneralFeedback(context, growthplanId) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/growthplan/${growthplanId}/get/general-feedback`, {
          growthplan_id: growthplanId,
        })
        .then((response) => {
          resolve(response.data.feedback);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Action that attempts to retrieve all feedback and growthplanSteps, to display which steps have received feedback.
   *
   * @param: {Vuex context} context - The current context of the store.
   * @param: {Number} generalFeedbackData.growthplan_id - GrowthPlan ID to retrieve data from.
   * @return: {Promise<Object>} response - Data from API in case of succesfull save.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  retrieveDashboardFeedback(context, growthplanId) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/growthplan/${growthplanId}/get/dashboard-feedback`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   *
   * @param: {Vuex context} context - The current context of the store.
   * @param: {Number} generalFeedbackData.growthplan_id - GrowthPlan ID to retrieve data from.
   * @return: {Promise<Object>} response - Data from API in case of succesfull save.
   * @return: {Reject<Error>} error - Any server error thrown by API that is not caught previously by Axios.
   */
  updateFeedbackSeen(context, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .patch(
          `/api/growthplan/${payload.growthplanId}/patch/feedback/${payload.feedbackId}/seen`,
          {
            seen: payload.seen,
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  setSavingFeedback(context, saving) {
    context.commit("setSavingFeedback", saving);
  },
  setFeedbackLastSaved(context) {
    context.commit("setFeedbackLastSaved", Date.now());
  },
  clearFeedbackLastSaved(context) {
    context.commit("setFeedbackLastSaved", null);
  },
};
