import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

const getDefaultState = () => {
  return {
    token: null,
    role: null,
  };
};

const state = getDefaultState();

actions.resetAuthState = (context) => {
  context.commit("resetAuthState");
};

mutations.resetAuthState = (state) => {
  Object.assign(state, getDefaultState());
};

export default {
  state,
  getters,
  mutations,
  actions,
};
