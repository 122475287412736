export default {
  alert(state) {
    return state.alert;
  },
  alertVariant(state) {
    return state.alertVariant;
  },
  alertData(state) {
    return {
      alert: state.alert,
      alertVariant: state.alertVariant,
    };
  },
  showAlert(state) {
    return state.showAlert;
  },
};
