import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";

import alerts from "./modules/alerts";
import auth from "./modules/auth";
import questions from "./modules/questions";
import growthplan from "./modules/growthplan";
import feedback from "./modules/feedback";
import client from "./modules/client";
import consultant from "./modules/consultant";
import admin from "./modules/admin";
import languages from "./modules/languages";

Vue.use(Vuex);

export default new Vuex.Store({
  actions: {
    setupStore(context) {
      context.commit("setDefaultAlert");
      context.commit("setDefaultLanguages");
    },
  },
  modules: {
    alerts: alerts,
    auth: auth,
    questions: questions,
    growthplan: growthplan,
    feedback: feedback,
    client: client,
    consultant: consultant,
    admin: admin,
    languages: languages,
  },
  getters: {
    isTestingE2e: () => {
      return process.env.VUE_APP_TEST_MODE === "e2e";
    },
  },
  plugins: [createPersistedState()],
});
